<template>
  <Fragment>
    <v-btn
      v-show="showCatalog"
      title="Hide list view"
      @click="$emit('update:showCatalog', false)"
      color="primary"
      x-small
      fab
      :style="{
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        left: catalogWidth,
      }"
      ><v-icon>{{ icons.mdiChevronDoubleLeft }}</v-icon>
    </v-btn>

    <v-btn
      v-show="!showCatalog"
      title="Show list view"
      @click="$emit('update:showCatalog', true)"
      color="primary"
      small
      fab
      :style="{
        position: 'absolute',
        zIndex: 1,
        bottom: '50px',
        left: '10px',
      }"
      ><v-icon>{{ icons.mdiDatabase }}</v-icon>
    </v-btn>
  </Fragment>
</template>

<script>
import { mdiChevronDoubleLeft, mdiDatabase } from '@mdi/js'
export default {
  props: {
    showCatalog: Boolean,
    catalogWidth: String,
  },
  data() {
    return {
      icons: { mdiChevronDoubleLeft, mdiDatabase },
    }
  },
}
</script>
