export const mapConfigs = {
  id: 'mainmap',
  zoom: 12,
  center: [85.3321, 23.35],
  tools: [
    {
      name: 'LayerControl',
      position: { top: '10px', right: '10px' },
    },
    {
      name: 'Scale',
      position: { bottom: '10px', right: '220px' },
    },
    {
      name: 'MousePosition',
      position: { bottom: '10px', right: '10px' },
    },
    {
      name: 'Zoom',
      position: { bottom: '50px', right: '10px' },
    },
    {
      name: 'ExtentControl',
      position: { bottom: '105px', right: '10px' },
    },
    {
      name: 'TileSwitcher',
      position: { bottom: '83px', right: '42px', size: '45' },
    },
    {
      name: 'Geolocation',
      position: { bottom: '50px', right: '42px' },
    },
    {
      name: 'FullScreen',
      position: { bottom: '50px', right: '74px' },
    },
    {
      name: 'Measure',
      position: { bottom: '50px', right: '106px' },
    },
    {
      name: 'Identify',
      position: { bottom: '50px', right: '137px' },
    },
    {
      name: 'FlashGeometry',
      position: {},
    },
    {
      name: 'AddFeature',
      position: {},
    },
    {
      name: 'Overlay',
      position: {},
    },
    {
      name: 'Search',
      position: { top: '10px', right: '380px' },
    },
    {
      name: 'Legend',
      position: { bottom: '10px', left: '10px' },
    },
  ],
}
