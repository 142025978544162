<template>
  <DragResize
    :active="!!report"
    :parent-id="mapId"
    :to="`${mapId}-draggable-elements`"
    :w="400"
    :h="340"
    :x="10"
    :y="-20"
    :z="2"
    :minw="340"
    :minh="340"
    dragHandle=".drag-report"
    preventActiveBehavior
  >
    <div
      class="drag-report"
      style="position: absolute; top: 0; height: 40px; width: calc(100% - 40px); z-index: 3; cursor: move"
    ></div>
    <v-card width="100%" height="100%">
      <v-card-title
        flat
        tile
        style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
        class="py-0 d-flex"
      >
        <v-icon size="18">{{ icons.mdiAdjust }}</v-icon>
        <span class="ml-2">Report: {{ reportId }}</span>

        <v-icon title="Close" size="18" style="margin-left: auto" @click="close">{{ icons.mdiClose }}</v-icon>
      </v-card-title>
      <v-tabs class="elevation-0" height="40" grow v-model="tab">
        <v-tab>Info</v-tab>
        <v-tab>Attachments</v-tab>
        <v-tab>Remarks</v-tab>
      </v-tabs>
      <Fragment v-if="report">
        <v-card-text v-show="tab === 0" class="mt-4 pb-0" style="height: calc(100% - 110px); overflow-y: auto">
          <v-row dense>
            <Fragment v-for="property in properties" :key="property.value">
              <v-col cols="5" class="font-weight-bold"> {{ property.text }} </v-col>
              <v-col cols="7" v-if="property.date"> {{ report[property.value] | date }} </v-col>
              <v-col cols="7" v-else-if="property.location" class="d-flex align-center">
                <span v-if="report[property.value]">{{ report[property.value].reverse().join(', ') }}</span>
                <v-icon
                  @click="zoomToLocation(report[property.value])"
                  class="ml-auto"
                  small
                  title="Zoom to location"
                  v-if="report[property.value]"
                  >{{ icons.mdiCrosshairs }}</v-icon
                >
                <v-icon
                  @click="copyLocation(report[property.value].reverse().join(', '))"
                  class="ml-auto"
                  small
                  title="Copy location"
                  v-if="report[property.value]"
                  >{{ icons.mdiContentCopy }}</v-icon
                >
                <span v-else>Undefined</span>
              </v-col>
              <v-col cols="7" v-else> {{ report[property.value] }} </v-col>
            </Fragment>
          </v-row>
        </v-card-text>

        <v-card-text v-show="tab === 1" class="mt-4 pb-0" style="height: calc(100% - 110px); overflow-y: auto">
          <h4>Images</h4>
          <ImageAttachment :images="attachments.images" v-if="attachments.images.length > 0" />
          <p v-else class="text-center mb-0">No image found</p>
          <h4 class="mt-2">Videos</h4>
          <VideoAttachment :videos="attachments.videos" v-if="attachments.videos.length > 0" />
          <p v-else class="text-center mb-0">No video found</p>
          <h4 class="mt-2">Documents</h4>
          <DocumentAttachment :documents="attachments.documents" v-if="attachments.documents.length > 0" />
          <p v-else class="text-center mb-0">No document found</p>
        </v-card-text>

        <v-card-text v-show="tab === 2" class="mt-4 pb-0" style="height: calc(100% - 110px); overflow-y: auto">
          <v-timeline dense clipped>
            <v-timeline-item fill-dot class="white--text" color="primary">
              <template v-slot:icon>
                <span>{{ ($store.state.auth?.user?.name || '') | sortName }}</span>
              </template>
              <v-textarea
                no-resize
                rows="2"
                auto-grow
                v-model="remark"
                :disabled="role === 'field_officer'"
                hide-details
                solo
                placeholder="Leave a remark"
                @keydown.enter="createReportRemark"
              >
                <template v-slot:append>
                  <v-btn class="mx-0" @click="createReportRemark" icon>
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-timeline-item>

            <v-timeline-item color="info" small v-for="remark in remarks" :key="remark.id">
              <v-card class="elevation-2">
                <v-card-text class="py-2">
                  <div class="d-flex align-center">
                    <span class="font-weight-bold"> {{ remark.user.name }}</span>
                    <span style="font-size: 12px" class="ml-auto">{{ remark.user.role.name }}</span>
                  </div>
                  <p class="my-1">{{ remark.remark }}</p>
                  <div class="d-flex align-center font-italic" style="font-size: 12px">
                    <v-icon size="13">{{ icons.mdiClockOutline }}</v-icon>
                    <span class="ml-1">{{ remark.createdAt | datetime }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </Fragment>
    </v-card>
  </DragResize>
</template>
<script>
import DragResize from '@/components/DragResize.vue'
import {
  mdiAdjust,
  mdiClose,
  mdiPlus,
  mdiAccountOutline,
  mdiClockOutline,
  mdiCrosshairs,
  mdiContentCopy,
} from '@mdi/js'
import { getReport, getReportRemarks, createReportRemark } from '@/api/change-detection'
import ImageAttachment from '../attachments/Image.vue'
import VideoAttachment from '../attachments/Video.vue'
import DocumentAttachment from '../attachments/Document.vue'
import { mapState } from 'vuex'
import { Point } from 'ol/geom'
import EventBus from '@/services/event-bus'

export default {
  props: { reportId: Number },
  components: {
    DragResize,
    ImageAttachment,
    VideoAttachment,
    DocumentAttachment,
  },
  data() {
    return {
      icons: { mdiAdjust, mdiClose, mdiPlus, mdiAccountOutline, mdiClockOutline, mdiCrosshairs, mdiContentCopy },
      tab: 0,
      report: null,
      attachments: {
        images: [],
        documents: [],
        videos: [],
      },
      remark: '',
      remarks: [],
    }
  },
  inject: ['mapId'],
  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
    properties() {
      if (!this.report) return []

      return this.report.isConfirmationReport
        ? [
            { text: 'ID', value: 'id' },
            { text: 'Detection ID', value: 'cdResultId' },
            { text: 'Verification Location', value: 'location', location: true },
            { text: 'Verification Date', value: 'verificationDate', date: true },
            { text: 'Remark', value: 'remark' },
          ]
        : [
            { text: 'ID', value: 'id' },
            { text: 'Detection ID', value: 'cdResultId' },
            { text: 'Type', value: 'type' },
            { text: 'Category', value: 'category' },
            { text: 'Verification Location', value: 'location', location: true },
            { text: 'Verification Date', value: 'verificationDate', date: true },
            { text: 'Permission Order Date', value: 'permissionOrderDate', date: true },
            { text: 'Site Type', value: 'siteType' },
            { text: 'Site Area', value: 'siteArea' },
            { text: 'Site Unit', value: 'siteUnit' },
            { text: 'Owner Name', value: 'ownerName' },
            { text: 'Owner Address', value: 'ownerAddress' },
            { text: 'Remark', value: 'remark' },
          ]
    },
  },
  watch: {
    reportId() {
      this.getReport()
      this.getReportRemarks()
    },
  },
  methods: {
    copyLocation(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message('Copied to clipboard', 'success')
        })
        .catch(err => {
          this.$message('Error copying text: ', err)
        })
    },

    zoomToLocation(location) {
      const point = new Point(location).transform('EPSG:4326', 'EPSG:3857')
      EventBus.$emit(`${this.mapId}-flash-geometry`, point)
    },

    close() {
      this.$emit('on-close')
    },

    async getReport() {
      if (!this.reportId) {
        this.report = null
        return
      }
      try {
        this.$loader(true)
        const { data } = await getReport(this.reportId)
        this.report = data
        this.attachments.images = data.cdReportAttachments.filter(attachment => attachment.type === 'image')
        this.attachments.documents = data.cdReportAttachments.filter(attachment => attachment.type === 'doc')
        this.attachments.videos = data.cdReportAttachments.filter(attachment => attachment.type === 'video')
      } finally {
        this.$loader(false)
      }
    },
    async getReportRemarks() {
      if (!this.reportId) {
        this.remarks = []
        return
      }
      try {
        this.$loader(true)
        const { data } = await getReportRemarks(this.reportId)
        this.remarks = data
      } finally {
        this.$loader(false)
      }
    },

    async createReportRemark() {
      if (!this.remark) return
      try {
        this.$loader(true)
        await createReportRemark(this.reportId, this.remark)
        this.remark = ''
        this.getReportRemarks()
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>
